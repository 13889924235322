<template>
  <div class="card p-3 shadow-sm">
    <template v-if="loading">
      <app-loading></app-loading>
    </template>
    <template v-else>
      <ci-list :entity="type" :entity_id="id" :entity_vessel_id="rsReport.vessel_id"
               @resetCompanyInstructions="initialize(false)"></ci-list>
    </template>
  </div>
</template>
<script>
import CIList from "../../ci/CIList.vue";

import {mapActions, mapGetters} from "vuex";
import AppLoading from "../../../elements/AppLoading.vue";

export default {
  components:{AppLoading,'ci-list':CIList},
  data(){
    return {
      type:'non_c',
      id:this.$route.query.non_conformity_id,
      loading:false,
    }
  },
  computed:{
    ...mapGetters(['companyInstructions','rsReport'])
  },
  methods:{
    ...mapActions(['getCompanyInstructions','getAirCiTypeKeys','getJobStatuses','getAcknowledgementStatuses']),
    async initialize(withLoading = false){
      if(withLoading){
        this.loading=true;
        await this.getCompanyInstructions({type:this.type,id:this.id});
        this.loading=false;
      }
      else {
        await this.getCompanyInstructions({type:this.type,id:this.id});
      }
    }
  },
  created() {
    this.initialize()
    this.getJobStatuses()
    this.getAcknowledgementStatuses();
    this.getAirCiTypeKeys();
  },
  mounted() {
    $(document).on('shown.bs.modal', function (e) {
      $(e.target).before($(e.target).find('.modal-backdrop').clone().css('z-index', $(e.target).css('z-index')-1));
      $(e.target).find('.modal-backdrop').removeClass('in').css('transition', 'none');
    });

    $(document).on('hide.bs.modal', function (e) {
      $(e.target).prev('.modal-backdrop').remove();
    });
  }
}
</script>

<style scoped></style>
